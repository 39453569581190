import {
  createBrandPlan,
  createBrandPlanPrice,
  updateBrandPlan,
} from '@/services/brand.service';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  CustomSectionHeader,
  PriceItemContainer,
  VisibilityContainer,
} from './styles';
import { BrandPlan, BrandPlanPrice } from './types';

import { formatISODate, formatMoney } from '@/utils';
import { find, orderBy, startCase } from 'lodash';
import { When } from 'react-if';
import { levelOptions, typeOptions } from './contants';
import { DeleteOutlined } from '@ant-design/icons';

type Args = {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  data?: BrandPlan | null;
  refresh?: () => void;
};

const visibilityOptions = [
  { label: 'Allowed', value: 'allowed', className: 'green' },
  { label: 'Preview', value: 'preview', className: 'gray' },
  { label: 'Not Allowed', value: 'notAllowed', className: 'red' },
];

const currencies = ['CAD', 'USD', 'NZD', 'AUD', 'EUR', 'GBP'];

const ModalForm: FC<Args> = ({ isOpen, onClose, refresh, data }) => {
  const [form] = Form.useForm();
  const [priceFormInstance] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<BrandPlan | null>(null);
  const [currentTab, setCurrentTab] = useState('plan-details');

  const level = form.getFieldValue('level');
  const type = form.getFieldValue('type');

  const [priceForm, setPriceForm] = useState<{
    open: boolean;
    loading: boolean;
    data: BrandPlanPrice;
  }>({
    open: false,
    loading: false,
    data: {
      id: '',
      currency: '',
      interval: 'month',
      amount: 0,
      extraPerSubmission: 0,
      isActive: true,
    },
  });

  useEffect(() => {
    setFormData(null);
    setCurrentTab('plan-details');

    if (form) {
      form.resetFields();
    }

    setTimeout(() => {
      if (isOpen && data && form) {
        form.setFieldsValue(data);
        setFormData(data);
      }
    }, 0);
  }, [isOpen, data, form]);

  const onFinish = async (values: any) => {
    const fields = {
      ...values,
      isActive: values.isActive || false,
    };

    let result = {
      status: false,
      message: '',
    };

    setLoading(true);

    if (data?.id) {
      result = await updateBrandPlan(data.id, fields);
    } else {
      result = await createBrandPlan(fields);
    }

    if (result.status) {
      notification.success({
        message: 'Success',
        description: 'Plan saved successfully',
      });
    } else {
      notification.error({
        message: 'Error',
        description: result.message,
      });
    }

    setLoading(false);

    refresh?.();
    onClose(false);
  };

  const priceInUse = useMemo(() => {
    const price = find(formData?.prices, (price) => {
      return (
        price.currency === priceForm?.data?.currency &&
        price.interval === priceForm?.data?.interval
      );
    });

    return !!price;
  }, [priceForm?.data?.interval, priceForm?.data?.currency, formData?.prices]);

  const onSavePrice = async (data: BrandPlanPrice) => {
    setPriceForm((prev) => ({
      ...prev,
      loading: true,
    }));

    try {
      const { status, prices } = await createBrandPlanPrice(
        formData?.id || '',
        data,
      );

      if (status) {
        setFormData((prev: any) => ({
          ...prev,
          prices,
        }));

        refresh?.();

        notification.success({
          message: 'Success',
          description: 'Price added successfully',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to add price',
      });
    }

    setPriceForm((prev) => ({
      ...prev,
      loading: false,
      open: false,
    }));
  };

  return (
    <Modal
      title={
        data?.id ? `Edit Plan ${data?.name?.en || ''}` : 'Create a new plan'
      }
      destroyOnClose
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => {
        onClose(false);
        setPriceForm((prev) => ({
          ...prev,
          open: false,
        }));
      }}
      okButtonProps={{
        disabled: false,
        loading,
        style: {
          display: currentTab === 'plan-details' ? 'inline-flex' : 'none',
        },
      }}
      okText={data?.id ? 'Save Plan' : 'Create Plan'}
      cancelButtonProps={{ disabled: false }}
      cancelText={currentTab === 'plan-details' ? 'Cancel' : 'Close'}
      width={900}
    >
      <Tabs
        activeKey={currentTab}
        onChange={(tab) => setCurrentTab(tab)}
        type="card"
        destroyInactiveTabPane={false}
        items={[
          {
            key: 'plan-details',
            label: 'Plan Details',
            children: (
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={(changedValues, allValues) => {
                  const limits = allValues.limits;
                  if (limits?.missionsPerCampaigns?.unlimited) {
                    limits.missionsPerCampaigns.total = null;
                  }
                  if (limits?.liveCampaigns?.unlimited) {
                    limits.liveCampaigns.total = null;
                  }
                  if (limits?.reachStores?.unlimited) {
                    limits.reachStores.total = null;
                  }
                  if (limits?.submissions?.unlimited) {
                    limits.submissions.total = null;
                  }
                  if (limits?.totalSales?.unlimited) {
                    limits.totalSales.total = null;
                  }
                  if (limits?.newsfeed?.unlimited) {
                    limits.newsfeed.total = null;
                  }

                  form.setFieldsValue({
                    ...allValues,
                    limits,
                  });

                  setFormData((prev) => ({
                    ...prev,
                    ...allValues,
                    limits,
                  }));
                }}
              >
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ margin: '4px 0 8px' }}
                >
                  Plan Name
                </Divider>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name={['name', 'en']}
                      label="English"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['name', 'fr']}
                      label="French"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ margin: '4px 0 8px' }}
                >
                  Plan Description
                </Divider>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      name={['description', 'en']}
                      label="English"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['description', 'fr']}
                      label="French"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ margin: '4px 0 8px' }}
                >
                  Category definitions
                </Divider>

                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      name="level"
                      label="Category"
                      rules={[{ required: true }]}
                      layout="vertical"
                    >
                      <Select
                        options={levelOptions}
                        onChange={(value) => {
                          if (value === 'pay-as-you-go') {
                            form.setFieldsValue({
                              type: 'contest',
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="type"
                      label="Type"
                      rules={[{ required: true }]}
                      layout="vertical"
                    >
                      <Select
                        options={typeOptions.filter((option) => {
                          if (level === 'pay-as-you-go') {
                            return ['contest', 'learning', 'general'].includes(
                              option.value,
                            );
                          }

                          return true;
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {level && level !== 'pay-as-you-go' && (
                  <>
                    <CustomSectionHeader>
                      <Divider
                        orientation="left"
                        orientationMargin="0"
                        style={{ margin: '4px 0 8px' }}
                      >
                        <span>Limit Definitions</span>
                      </Divider>
                    </CustomSectionHeader>

                    <Space direction="vertical">
                      {!['newsfeed', 'consumer'].includes(type) && (
                        <Space>
                          <Form.Item
                            name={['limits', 'reachStores', 'total']}
                            label="Active Stores Target"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  ['general', 'learning'].includes(type) ||
                                  !formData?.limits?.reachStores?.unlimited,
                                message: 'Required',
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={
                                !['general', 'learning'].includes(type) &&
                                formData?.limits?.reachStores?.unlimited
                              }
                            />
                          </Form.Item>

                          {!['general', 'learning'].includes(type) && (
                            <Form.Item
                              name={['limits', 'reachStores', 'unlimited']}
                            >
                              <Switch
                                checkedChildren="Unlimited"
                                unCheckedChildren="Limited"
                              />
                            </Form.Item>
                          )}
                        </Space>
                      )}
                      <When
                        condition={!['newsfeed'].includes(formData?.type || '')}
                      >
                        <Space>
                          <Form.Item
                            name={['limits', 'liveCampaigns', 'total']}
                            label="Live Campaigns"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  !formData?.limits?.liveCampaigns?.unlimited,
                                message: 'Required',
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={
                                formData?.limits?.liveCampaigns?.unlimited
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            name={['limits', 'liveCampaigns', 'unlimited']}
                          >
                            <Switch
                              checkedChildren="Unlimited"
                              unCheckedChildren="Limited"
                            />
                          </Form.Item>
                        </Space>
                      </When>

                      <When
                        condition={!['newsfeed'].includes(formData?.type || '')}
                      >
                        <Space>
                          <Form.Item
                            name={['limits', 'submissions', 'total']}
                            label="Submissions"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  !formData?.limits?.submissions?.unlimited,
                                message: 'Required',
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={
                                formData?.limits?.submissions?.unlimited
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            name={['limits', 'submissions', 'unlimited']}
                          >
                            <Switch
                              checkedChildren="Unlimited"
                              unCheckedChildren="Limited"
                            />
                          </Form.Item>
                        </Space>
                      </When>

                      <When condition={formData?.type === 'contest'}>
                        <Space>
                          <Form.Item
                            name={['limits', 'totalSales', 'total']}
                            label="Sales Amount"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  !formData?.limits?.totalSales?.unlimited,
                                message: 'Required',
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={formData?.limits?.totalSales?.unlimited}
                            />
                          </Form.Item>

                          <Form.Item
                            name={['limits', 'totalSales', 'unlimited']}
                          >
                            <Switch
                              checkedChildren="Unlimited"
                              unCheckedChildren="Limited"
                            />
                          </Form.Item>
                        </Space>
                      </When>

                      <When
                        condition={
                          !['newsfeed'].includes(formData?.type || '') &&
                          formData?.type === 'learning'
                        }
                      >
                        <Space>
                          <Form.Item
                            name={['limits', 'missionsPerCampaigns', 'total']}
                            label="Missions Per Campaign"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  !formData?.limits?.missionsPerCampaigns
                                    ?.unlimited,
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={
                                formData?.limits?.missionsPerCampaigns
                                  ?.unlimited
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            name={[
                              'limits',
                              'missionsPerCampaigns',
                              'unlimited',
                            ]}
                          >
                            <Switch
                              checkedChildren="Unlimited"
                              unCheckedChildren="Limited"
                            />
                          </Form.Item>
                        </Space>
                      </When>

                      <When condition={formData?.type === 'newsfeed'}>
                        <Space>
                          <Form.Item
                            name={['limits', 'newsfeed', 'total']}
                            label="Newsfeed"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required:
                                  !formData?.limits?.newsfeed?.unlimited,
                              },
                            ]}
                          >
                            <InputNumber
                              min={1}
                              disabled={formData?.limits?.newsfeed?.unlimited}
                            />
                          </Form.Item>

                          <Form.Item name={['limits', 'newsfeed', 'unlimited']}>
                            <Switch
                              checkedChildren="Unlimited"
                              unCheckedChildren="Limited"
                            />
                          </Form.Item>
                        </Space>
                      </When>
                    </Space>
                  </>
                )}

                {level &&
                  level === 'pay-as-you-go' &&
                  ['contest', 'learning', 'general'].includes(type) && (
                    <>
                      <CustomSectionHeader>
                        <Divider
                          orientation="left"
                          orientationMargin="0"
                          style={{ margin: '4px 0 8px' }}
                        >
                          <span>Usage Rules</span>
                        </Divider>
                      </CustomSectionHeader>

                      <Space direction="vertical">
                        {type === 'contest' && (
                          <Form.Item
                            name={['usageRules', 'totalSales', 'percentage']}
                            label="Percentage of Sales"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[{ required: true }]}
                          >
                            <InputNumber
                              min={0}
                              max={100}
                              formatter={(value) => `${value}%`}
                              parser={(value): 0 | 100 => {
                                const num = value
                                  ? Number(value.replace('%', ''))
                                  : 0;
                                return num > 100
                                  ? 100
                                  : num < 0
                                    ? 0
                                    : (num as 0 | 100);
                              }}
                            />
                          </Form.Item>
                        )}

                        {type !== 'contest' && (
                          <Form.Item
                            name={['usageRules', 'activeStores', 'prices']}
                            label="Price per Active Store"
                            layout="horizontal"
                            labelCol={{ style: { width: '220px' } }}
                            rules={[
                              {
                                required: true,
                                validator: (_, value) => {
                                  if (!value || value.length === 0) {
                                    return Promise.reject(
                                      'At least one currency price is required',
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Form.List
                              name={['usageRules', 'activeStores', 'prices']}
                            >
                              {(fields, { add, remove }) => (
                                <Space
                                  direction="vertical"
                                  style={{ width: '100%' }}
                                >
                                  {fields.map((field, index) => (
                                    <Space key={field.key}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'currency']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Currency is required',
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <Select
                                          style={{ width: 120 }}
                                          options={currencies
                                            .filter((curr) => {
                                              const values =
                                                form.getFieldValue([
                                                  'usageRules',
                                                  'activeStores',
                                                  'prices',
                                                ]) || [];
                                              return !values.some(
                                                (item: any, i: number) =>
                                                  item?.currency === curr &&
                                                  i !== field.name,
                                              );
                                            })
                                            .map((curr) => ({
                                              label: curr,
                                              value: curr,
                                            }))}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'price']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Price is required',
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value <= 0) {
                                                return Promise.reject(
                                                  'Price must be greater than 0',
                                                );
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <InputNumber
                                          min={0}
                                          step={0.01}
                                          precision={2}
                                          formatter={(value) => `$ ${value}`}
                                          parser={(
                                            value: string | undefined,
                                          ): number =>
                                            value
                                              ? Number(value.replace('$ ', ''))
                                              : 0
                                          }
                                        />
                                      </Form.Item>
                                      {fields.length > 1 && (
                                        <Button
                                          type="link"
                                          onClick={() => remove(index)}
                                          icon={<DeleteOutlined />}
                                        />
                                      )}
                                    </Space>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      add({ currency: '', price: 0 })
                                    }
                                    block
                                  >
                                    Add Currency Price
                                  </Button>
                                </Space>
                              )}
                            </Form.List>
                          </Form.Item>
                        )}
                      </Space>
                    </>
                  )}

                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ margin: '4px 0 8px' }}
                >
                  WebApp Pages Visibility
                </Divider>

                <VisibilityContainer>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        name={['appVisibility', 'stores']}
                        label="Stores"
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Campaigns"
                        name={['appVisibility', 'campaigns']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Missions"
                        name={['appVisibility', 'missions']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tax Form"
                        name={['appVisibility', 'taxForm']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Groups"
                        name={['appVisibility', 'groups']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Data & Insights"
                        name={['appVisibility', 'dataInsights']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Submissions"
                        name={['appVisibility', 'submissions']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Newsfeed"
                        name={['appVisibility', 'newsfeed']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Forms"
                        name={['appVisibility', 'forms']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Inbox"
                        name={['appVisibility', 'inbox']}
                        rules={[{ required: true }]}
                        layout="horizontal"
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                      >
                        <Segmented
                          defaultValue={null}
                          options={visibilityOptions}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </VisibilityContainer>
              </Form>
            ),
          },
          {
            key: 'plan-prices',
            label: (
              <Space.Compact direction="vertical">
                <span>Plan Prices</span>
                {!data?.id && (
                  <span style={{ fontSize: 12, color: '#e84040' }}>
                    Will be available after create the plan
                  </span>
                )}
              </Space.Compact>
            ),
            disabled: !data?.id,
            children: (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Popconfirm
                  open={priceForm.open && isOpen}
                  icon={null}
                  title="Create new price"
                  okText="Create"
                  onConfirm={() => {
                    priceFormInstance.submit();
                  }}
                  onCancel={() =>
                    setPriceForm((prev) => ({ ...prev, open: false }))
                  }
                  okButtonProps={{ loading: priceForm.loading }}
                  description={
                    <Form
                      form={priceFormInstance}
                      style={{ width: 300, marginTop: 16 }}
                      onFinish={(values) => onSavePrice(values)}
                      layout="horizontal"
                      onValuesChange={(changedValues, allValues) => {
                        setPriceForm((prev) => ({
                          ...prev,
                          data: {
                            ...prev?.data,
                            ...allValues,
                          },
                        }));
                      }}
                    >
                      <Form.Item
                        name="amount"
                        label="Amount"
                        rules={[
                          { required: true },
                          {
                            validator: (_, value) => {
                              if (value <= 0) {
                                return Promise.reject(
                                  'Amount must be greater than 0',
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        labelCol={{ style: { width: '110px' } }}
                      >
                        <InputNumber style={{ width: '100%' }} size="small" />
                      </Form.Item>

                      <Form.Item
                        name="extraPerSubmission"
                        label="Extra Amount"
                        rules={[{ required: true }]}
                        labelCol={{ style: { width: '110px' } }}
                        help="Extra amount per submission"
                      >
                        <InputNumber style={{ width: '100%' }} size="small" />
                      </Form.Item>

                      <Form.Item
                        name="currency"
                        label="Currency"
                        rules={[{ required: true }]}
                        labelCol={{ style: { width: '110px' } }}
                      >
                        <Select
                          size="small"
                          options={currencies.map((currency) => ({
                            value: currency,
                            label: currency,
                          }))}
                        />
                      </Form.Item>

                      <Form.Item
                        name="interval"
                        label="Interval"
                        rules={[{ required: true }]}
                        labelCol={{ style: { width: '110px' } }}
                      >
                        <Select
                          size="small"
                          options={[
                            { value: 'month', label: 'Monthly' },
                            { value: 'year', label: 'Yearly' },
                          ]}
                        />
                      </Form.Item>

                      <When condition={priceInUse}>
                        <Alert
                          message={
                            <>
                              There is another active price for the interval{' '}
                              <strong>
                                {startCase(priceForm?.data.interval || 'month')}
                              </strong>{' '}
                              and currency{' '}
                              <strong>
                                {priceForm?.data.currency || 'USD'}
                              </strong>
                              , if you proceed, all new subscriptions will be
                              using this price instead the preview one.
                            </>
                          }
                          type="warning"
                          showIcon
                        />
                      </When>
                    </Form>
                  }
                  showArrow
                  showCancel
                >
                  <Button
                    onClick={() => {
                      priceFormInstance.resetFields();
                      setPriceForm((prev) => ({
                        ...prev,
                        open: true,
                        data: {
                          ...prev?.data,
                          id: '',
                        },
                      }));
                    }}
                    type="primary"
                  >
                    Add Price
                  </Button>
                </Popconfirm>

                <List
                  itemLayout="vertical"
                  dataSource={orderBy(formData?.prices, ['isActive'], ['desc'])}
                  pagination={{
                    pageSize: 5,
                  }}
                  renderItem={(price) => (
                    <List.Item key={price.id}>
                      <PriceItemContainer>
                        <div className="title">
                          <strong>
                            {price.currency} -{' '}
                            {price.interval === 'month' ? 'Monthly' : 'Yearly'}
                          </strong>
                          {price.isActive && (
                            <Tag color="green">Current Active</Tag>
                          )}
                        </div>
                        <Descriptions
                          column={1}
                          bordered={false}
                          size="small"
                          labelStyle={{ fontSize: 12 }}
                          contentStyle={{ fontSize: 12 }}
                        >
                          <Descriptions.Item label="Amount">
                            {formatMoney(price.amount, price.currency)}
                          </Descriptions.Item>
                          <Descriptions.Item label="Extra per Submission Amount">
                            {formatMoney(
                              price.extraPerSubmission,
                              price.currency,
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="Stripe Price ID">
                            {price.id || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="Created At">
                            {price.createdAt
                              ? formatISODate(price.createdAt)
                              : '-'}
                          </Descriptions.Item>
                        </Descriptions>
                      </PriceItemContainer>
                    </List.Item>
                  )}
                />
              </Space>
            ),
          },
        ].filter(
          (row) =>
            row.key !== 'plan-prices' || (level !== 'pay-as-you-go' && level),
        )}
      />
    </Modal>
  );
};

export default ModalForm;
