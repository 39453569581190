import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Switch as SwitchInput,
} from 'antd';
import { map, startCase } from 'lodash';
import { PaymentFormContainer } from '../styles';
import { MobilePayments } from '@/types';

interface MobilePaymentsSettingsProps {
  form: any;
  onFinish: (values: any) => void;
  disabled: boolean;
  onFieldsChange: () => void;
  hasChanges: boolean;
  onCancel: () => void;
  saving: boolean;
  mobilePayments: MobilePayments;
}

export function MobilePaymentsSettings({
  form,
  onFinish,
  disabled,
  onFieldsChange,
  hasChanges,
  onCancel,
  saving,
  mobilePayments,
}: MobilePaymentsSettingsProps) {
  return (
    <PaymentFormContainer>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
      >
        {map(mobilePayments, (item, index) => (
          <div key={`${index}-${item.type}`}>
            <Divider orientation="left" orientationMargin="0">
              {startCase(item.type)}
            </Divider>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name={['payments', index, 'isActive']}
                  valuePropName="checked"
                  help="Enable or disable this payment method"
                >
                  <SwitchInput
                    checkedChildren="Actived"
                    unCheckedChildren="inactived"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['payments', index, 'minValue']}
                  rules={[{ required: true }]}
                  help="Minimum value required to use this payment method without fees"
                >
                  <InputNumber
                    placeholder="Min Value"
                    addonAfter="$"
                    style={{ width: '90%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['payments', index, 'fee']}
                  rules={[{ required: true }]}
                  help="Fee percentage charged to the user"
                >
                  <InputNumber
                    placeholder="Fee"
                    addonAfter="%"
                    style={{ width: '90%' }}
                  />
                </Form.Item>
              </Col>
              <Form.Item name={['payments', index, 'type']} hidden={true}>
                <Input />
              </Form.Item>
            </Row>
          </div>
        ))}
        <Form.Item className="actions">
          {hasChanges && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" disabled={saving}>
                Save
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </PaymentFormContainer>
  );
}
