import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Tabs } from 'antd';
import { Container, TabsContainer, TabsControlContainer } from './styles';
import { useSettingsController } from './useSettingsController';
import { GlobalSettings } from './components/GlobalSettings';
import { AIScanSettings } from './components/AIScanSettings';
import { WebappMissionsSettings } from './components/WebappMissionsSettings';
import { DepositBankInfoSettings } from './components/DepositBankInfoSettings';
import { MobilePaymentsSettings } from './components/MobilePaymentsSettings';
import { ScanSettings } from './components/ScanSettings';

const SETTINGS_TABS = [
  // {
  //   key: 'mobile_payments',
  //   label: 'Mobile Payments',
  // },
  {
    key: 'global',
    label: 'Global',
  },
  {
    key: 'ai-scan',
    label: 'AI Scan',
  },
  {
    key: 'scan',
    label: 'Scan',
  },
  {
    key: 'webapp-missions',
    label: 'Webapp Missions',
  },
];

export function Settings() {
  const {
    mobilePayments,
    loadingPayments,
    form,
    disabled,
    onFinish,
    onFieldsChange,
    hasChanges,
    savingSettings,
    onCancel,
    currentTab,
    setCurrentTab,
    loadingSettings,
  } = useSettingsController();

  const renderSettingsForm = () => {
    switch (currentTab) {
      case 'mobile_payments':
        if (!mobilePayments) {
          return null;
        }

        return (
          <MobilePaymentsSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
            mobilePayments={mobilePayments}
          />
        );

      case 'global':
        return (
          <GlobalSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
          />
        );

      case 'ai-scan':
        return (
          <AIScanSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
          />
        );

      case 'scan':
        return (
          <ScanSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
          />
        );

      case 'webapp-missions':
        return (
          <WebappMissionsSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
          />
        );

      case 'deposit-bank-info':
        return (
          <DepositBankInfoSettings
            form={form}
            onFinish={onFinish}
            disabled={disabled}
            onFieldsChange={onFieldsChange}
            hasChanges={hasChanges}
            onCancel={onCancel}
            saving={savingSettings}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Layout>
      <PageHeader title="General Settings" />
      <Container>
        <TabsControlContainer>
          <Tabs
            activeKey={currentTab}
            onChange={setCurrentTab}
            items={SETTINGS_TABS}
          />
        </TabsControlContainer>

        <TabsContainer key={`key-${currentTab}`}>
          {renderSettingsForm()}
        </TabsContainer>
      </Container>
    </Layout>
  );
}
