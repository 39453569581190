import { Form, InputNumber, Button, Divider } from 'antd';
import { PaymentFormContainer } from '../styles';

interface ScanSettingsProps {
  form: any;
  onFinish: (values: any) => void;
  disabled: boolean;
  onFieldsChange: () => void;
  hasChanges: boolean;
  onCancel: () => void;
  saving: boolean;
}

export function ScanSettings({
  form,
  onFinish,
  disabled,
  onFieldsChange,
  hasChanges,
  onCancel,
  saving,
}: ScanSettingsProps) {
  return (
    <PaymentFormContainer>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
        layout="horizontal"
      >
        <Divider orientation="left" orientationMargin="0">
          High Priority Settings
        </Divider>

        <Form.Item
          name={['values', 'high', 'userQualityIndex', 'green']}
          label="Green Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'high', 'userQualityIndex', 'yellow']}
          label="Yellow Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'high', 'userQualityIndex', 'orange']}
          label="Orange Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'high', 'userQualityIndex', 'red']}
          label="Red Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Low Priority Settings
        </Divider>

        <Form.Item
          name={['values', 'low', 'userQualityIndex', 'green']}
          label="Green Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'low', 'userQualityIndex', 'yellow']}
          label="Yellow Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'low', 'userQualityIndex', 'orange']}
          label="Orange Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'low', 'userQualityIndex', 'red']}
          label="Red Quality Index"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '150px' }} />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Services
        </Divider>

        {['azure', 'verify', 'custom'].map((service) => (
          <Form.Item
            key={service}
            name={['values', 'services', service, 'chance']}
            label={`${
              service.charAt(0).toUpperCase() + service.slice(1)
            } Chance`}
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: '150px' }}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => {
                const num = value ? Number(value.replace('%', '')) : 0;
                return Math.min(Math.max(num, 0), 100) as 0 | 100;
              }}
            />
          </Form.Item>
        ))}

        <Form.Item className="actions">
          {hasChanges && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" disabled={saving}>
                Save
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </PaymentFormContainer>
  );
}
