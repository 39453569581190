import { TabsContainer } from '@/styles/tabs';
import styled from 'styled-components';

export const TabContainer = styled(TabsContainer)`
  .store-column {
    .info {
      gap: 0 !important;

      h5 {
        margin-bottom: 0;
      }
    }
  }
`;
