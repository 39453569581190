import { handleError, httpPost } from './util/axios';

export const rerunSmartReviewManually = async (storeId: string) => {
  try {
    return await httpPost(
      'receipt-scan',
      'external/rerun-smart-review-manually',
      {
        storeId,
      },
    );
  } catch (error: any) {
    handleError(error);
  }
};
