import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import TagsRender from '@/pages/Brand/components/TagsRender';
import MobileUserList from '@/pages/MobileUser/List';
import BrandsTab from '@/pages/Store/Manage/brands-tab';
import { getStoreDetails } from '@/services/store.service';
import { TabsContainer, TabsControlContainer } from '@/styles/tabs';
import { Store } from '@/types';
import { formatISODate } from '@/utils';
import {
  Avatar,
  Button,
  Descriptions,
  notification,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { IoPlanetOutline } from 'react-icons/io5';
import { PiPencilLineBold } from 'react-icons/pi';
import { TbTools } from 'react-icons/tb';
import { VscDebugRerun } from 'react-icons/vsc';
import { Case, Switch, When } from 'react-if';
import { useParams } from 'react-router-dom';
import { StorePageHeader } from './styles';

import { ButtonLink } from '@/components/ButtonLink';
import { useAudit } from '@/hooks/use-audit';
import { rerunSmartReviewManually } from '@/services/receipt-scan.service';

const YesNoRender = ({ value }: { value: boolean | undefined }) => {
  return (
    <Switch>
      <Case condition={value}>
        <Typography.Text type="success">Yes</Typography.Text>
      </Case>
      <Case condition={!value}>
        <Typography.Text type="danger">No</Typography.Text>
      </Case>
    </Switch>
  );
};

const ManagePage = () => {
  const { storeId = '' } = useParams<{ storeId: string }>();
  const [currentTab, setCurrentTab] = useState('tab-users');

  const [store, setStore] = useState<Store>();

  const audit = useAudit({
    resourceName: 'receipt-scan',
  });

  useEffect(() => {
    loadStore();
  }, []);

  const loadStore = async () => {
    const result = await getStoreDetails(storeId);

    setStore(result?.store);
  };

  async function handleRerunReceipts() {
    try {
      const data = await rerunSmartReviewManually(storeId);

      audit.onClick({
        target: 'receipt-scan',
        data: {
          storeId,
        },
      });

      if (data.status) {
        notification.success({
          message: 'Success',
          description: `Rerun receipts successfully`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: `Rerun receipts failed.`,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: `Rerun receipts failed.`,
      });
    }
  }

  return (
    <Layout>
      <PageHeader title="Store Manage">
        <Space>
          <Button
            onClick={() => handleRerunReceipts()}
            icon={<VscDebugRerun />}
          >
            Rerun Smart Review Receipts
          </Button>
          <ButtonLink
            to={`/store/${storeId}/edit`}
            icon={<PiPencilLineBold />}
            type="primary"
          >
            Edit Store
          </ButtonLink>
          <Button icon={<TbTools />} type="primary" danger>
            Export Tools
          </Button>
        </Space>
      </PageHeader>

      <StorePageHeader>
        <div className="main-info">
          <div className="avatar">
            <Avatar
              size={200}
              src={store?.logoUrl}
              shape="square"
              icon={<IoPlanetOutline />}
            />
          </div>
          <div className="info">
            <Space.Compact direction={'vertical'} className="name-container">
              <Typography.Title level={2}>{store?.name}</Typography.Title>
              <Typography.Text>
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Address: </span>
                {store?.address.formattedAddress}
              </Typography.Text>
              <Descriptions column={1}>
                <Descriptions.Item label="ID">
                  <Typography.Text copyable>{store?.id}</Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            </Space.Compact>

            <div className="details">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Local Currency">
                  {store?.currency}
                </Descriptions.Item>
                <Descriptions.Item label="Industries">
                  <When condition={store?.storeIndustries?.length === 0}>
                    <Tag style={{ borderStyle: 'dashed' }}>None</Tag>
                  </When>
                  <When
                    condition={
                      store?.storeIndustries &&
                      store?.storeIndustries.length > 0
                    }
                  >
                    <TagsRender
                      max={2}
                      items={map(store?.storeIndustries, (row) => row.name)}
                    />
                  </When>
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date">
                  {formatISODate(store?.createdAt)}
                </Descriptions.Item>

                <Descriptions.Item label="Hidden">
                  <YesNoRender value={store?.isHidden} />
                </Descriptions.Item>

                <Descriptions.Item label="Locked">
                  <YesNoRender value={store?.isLocked} />
                </Descriptions.Item>

                <Descriptions.Item label="Private">
                  <YesNoRender value={store?.isPrivate} />
                </Descriptions.Item>

                <Descriptions.Item label="Exclusive">
                  <YesNoRender value={store?.isExclusive} />
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </StorePageHeader>

      <TabsControlContainer>
        <Tabs
          items={[
            {
              key: 'tab-users',
              label: 'Users',
            },
            {
              key: 'tab-brands',
              label: 'Brands',
            },
          ]}
          onChange={(tab: string) => setCurrentTab(tab)}
        />
      </TabsControlContainer>

      <TabsContainer>
        <Switch>
          <Case condition={currentTab === 'tab-users' && store !== null}>
            <MobileUserList mode={'subpage'} storeId={storeId} />
          </Case>
          <Case condition={currentTab === 'tab-brands' && store !== null}>
            {store && (
              <BrandsTab
                storeId={storeId}
                isExclusiveStore={store.isExclusive ?? false}
              />
            )}
          </Case>
        </Switch>
      </TabsContainer>
    </Layout>
  );
};

export default ManagePage;
