import {
  Form,
  InputNumber,
  Button,
  Select,
  Divider,
  Input,
  Switch,
} from 'antd';
import { PaymentFormContainer } from '../styles';

interface GlobalSettingsProps {
  form: any;
  onFinish: (values: any) => void;
  disabled: boolean;
  onFieldsChange: () => void;
  hasChanges: boolean;
  onCancel: () => void;
  saving: boolean;
}

export function GlobalSettings({
  form,
  onFinish,
  disabled,
  onFieldsChange,
  hasChanges,
  onCancel,
  saving,
}: GlobalSettingsProps) {
  return (
    <PaymentFormContainer>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
        layout="horizontal"
      >
        <Divider orientation="left" orientationMargin="0">
          Base Settings
        </Divider>

        <Form.Item
          name={['values', 'baseEarnValue']}
          label="Base Earn Value"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '200px' }} min={0} />
        </Form.Item>

        <Form.Item
          name={['values', 'baseScanPricePerUnit']}
          label="Base Scan Price Per Unit"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '200px' }} min={0} step={0.01} />
        </Form.Item>

        <Form.Item
          name={['values', 'defaultBrandPaymentPlan']}
          label="Default Brand Payment Plan"
          rules={[{ required: true }]}
        >
          <Select style={{ width: '200px' }}>
            <Select.Option value="prepai">Prepaid</Select.Option>
            <Select.Option value="postpaid">Postpaid</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={['values', 'notifyCampaignLowBudget']}
          label="Notify Campaign Low Budget"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '200px' }} min={0} />
        </Form.Item>

        <Form.Item
          name={['values', 'notifyEndingCampaign']}
          label="Notify Ending Campaign"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '200px' }} min={0} />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Onboarding Campaign
        </Divider>

        <Form.Item
          name={['values', 'onboardingCampaign', 'bundleId']}
          label="Bundle ID"
          rules={[{ required: true }]}
        >
          <Input style={{ width: '250px' }} />
        </Form.Item>

        <Form.Item
          name={['values', 'onboardingCampaign', 'missionId']}
          label="Mission ID"
          rules={[{ required: true }]}
        >
          <Input style={{ width: '200px' }} />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Campaign Launch
        </Divider>

        <Form.Item
          name={['values', 'requestLaunchReview']}
          label="Request Launch Review"
          rules={[{ required: true }]}
        >
          <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
        </Form.Item>

        <Form.Item className="actions">
          {hasChanges && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" disabled={saving}>
                Save
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </PaymentFormContainer>
  );
}
