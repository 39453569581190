import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { updatePlanActiveStatus } from '@/services/brand.service';
import { TableContainer } from '@/styles';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Space, Table, Tag, Typography } from 'antd';
import { find, get, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { MdEditSquare } from 'react-icons/md';
import { levelOptions, typeOptions } from './contants';
import ModalForm from './modal-form';
import { BrandPlan, BrandPlanLevel } from './types';
import { formatMoney } from '@/utils';

const PlanComponent = () => {
  const [filter, setFilter] = useState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<BrandPlan | null>(null);

  const { tableProps, refresh } = useTable<BrandPlan>({
    service: 'brand/read',
    path: `admin/plan/list`,
    params: {
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'name.en',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'brand-plan',
  });

  useEffect(() => {
    if (isOpenForm) {
      const plan = find(
        tableProps.dataSource,
        (plan) => plan.id === currentPlan?.id,
      );

      if (plan) {
        setCurrentPlan(plan);
      }
    }
  }, [currentPlan?.id, isOpenForm, tableProps.dataSource]);

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Brand Plans">
        <Space>
          <Input.Search
            placeholder="Search by plan name"
            allowClear
            onSearch={(value) => {
              setFilter(value);
            }}
            style={{ width: 260 }}
          />
          <Button
            type="primary"
            onClick={() => {
              setCurrentPlan(null);
              setTimeout(() => setOpenForm(true), 0);
            }}
            icon={<PlusOutlined />}
          >
            Add Plan
          </Button>
        </Space>
      </PageHeader>

      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Plan Name"
            dataIndex="name"
            key="name"
            sorter
            render={(data) => {
              return (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text>EN: {get(data, 'en', '-')}</Typography.Text>
                  <Typography.Text>FR: {get(data, 'fr', '-')}</Typography.Text>
                </Space.Compact>
              );
            }}
          />
          <Table.Column
            title="Category"
            dataIndex="level"
            key="level"
            filters={levelOptions}
            render={(val) => startCase(val)}
          />
          <Table.Column
            title="Type"
            dataIndex="type"
            key="type"
            sorter
            filters={typeOptions}
            render={(val) => {
              if (!val) return '-';
              const row = typeOptions.find((item: any) => item.value === val);
              return row?.label;
            }}
          />
          <Table.Column
            title="Usage / Limits"
            dataIndex="limits"
            key="limits"
            render={(limits: any, record: BrandPlan) => {
              if (record.level === BrandPlanLevel.PAY_AS_YOU_GO) {
                if (record.type === 'contest') {
                  const percentage = get(
                    record,
                    'usageRules.totalSales.percentage',
                  );
                  return percentage ? `${percentage}% of sales` : '-';
                } else {
                  const prices = get(
                    record,
                    'usageRules.activeStores.prices',
                    [],
                  );
                  if (prices.length > 0) {
                    return (
                      <Space direction="vertical" size="small">
                        {prices.map((price: any, index: number) => (
                          <Typography.Text key={index}>
                            {`${price.currency}: ${formatMoney(
                              price.price,
                              price.currency,
                            )}/store`}
                          </Typography.Text>
                        ))}
                      </Space>
                    );
                  }
                  return '-';
                }
              } else {
                const limitSummary = [];

                if (
                  limits?.reachStores?.total &&
                  !limits?.reachStores?.unlimited
                ) {
                  limitSummary.push(`${limits.reachStores.total} stores`);
                }

                if (
                  limits?.liveCampaigns?.total &&
                  !limits?.liveCampaigns?.unlimited
                ) {
                  limitSummary.push(`${limits.liveCampaigns.total} campaigns`);
                }

                if (
                  limits?.submissions?.total &&
                  !limits?.submissions?.unlimited
                ) {
                  limitSummary.push(`${limits.submissions.total} submissions`);
                }

                if (
                  limits?.totalSales?.total &&
                  !limits?.totalSales?.unlimited
                ) {
                  limitSummary.push(`$${limits.totalSales.total} sales`);
                }

                if (
                  limits?.missionsPerCampaigns?.total &&
                  !limits?.missionsPerCampaigns?.unlimited
                ) {
                  limitSummary.push(
                    `${limits.missionsPerCampaigns.total} missions/campaign`,
                  );
                }

                if (limits?.newsfeed?.total && !limits?.newsfeed?.unlimited) {
                  limitSummary.push(`${limits.newsfeed.total} newsfeed`);
                }

                return limitSummary.length > 0 ? (
                  <Space direction="vertical" size="small">
                    {limitSummary.map((limit, index) => (
                      <Typography.Text key={index}>{limit}</Typography.Text>
                    ))}
                  </Space>
                ) : (
                  <Tag color="success">Unlimited</Tag>
                );
              }
            }}
          />
          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            sorter
            filters={[
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ]}
            render={(text: any, record: BrandPlan, index: number) => {
              if (record.isActive) {
                return <Tag color="success">Active</Tag>;
              }

              return <Tag color="error">Inactive</Tag>;
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: BrandPlan, index: number) => {
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'edit',
                        label: 'Edit',
                        icon: <MdEditSquare size={20} />,
                        onClick: () => {
                          setCurrentPlan(record);
                          setOpenForm(true);
                        },
                      },
                      {
                        key: 'change-status',
                        label: record.isActive ? 'Deactivate' : 'Activate',
                        danger: record.isActive,
                        icon: !record.isActive ? (
                          <FaCheckCircle size={20} />
                        ) : (
                          <FaTimesCircle size={20} />
                        ),
                        onClick: async () => {
                          if (record.id) {
                            await updatePlanActiveStatus(
                              record.id,
                              !record.isActive,
                            );
                            refresh();
                          }
                        },
                      },
                    ],
                  }}
                >
                  <Button size={'small'}>
                    <HiOutlineDotsHorizontal size={20} />
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </TableContainer>
      <ModalForm
        isOpen={isOpenForm}
        onClose={setOpenForm}
        refresh={refresh}
        data={currentPlan}
      />
    </Layout>
  );
};

export default PlanComponent;
