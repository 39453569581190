import { handleError, httpGet, httpPost } from './util/axios';

interface SettingsResponse {
  status: boolean;
  result: Array<{
    _id: string;
    type: string;
    values: any;
    updatedAt?: string;
  }>;
}

interface SaveSettingsResponse {
  status: boolean;
  result: {
    type: string;
    values: any;
  };
}

interface SaveSettingsPayload {
  type: string;
  values: any;
}

export async function getSettings(type: string) {
  try {
    return httpGet('admin-portal', `settings?type=${type}`) as Promise<{
      type: string;
      values: any;
    }>;
  } catch (error: any) {
    handleError(error);
    return undefined;
  }
}

export async function saveSettings(
  payload: SaveSettingsPayload,
): Promise<SaveSettingsResponse | undefined> {
  try {
    return httpPost('admin-portal', 'settings', payload);
  } catch (error: any) {
    handleError(error);
    return undefined;
  }
}
