import { useAudit, useTable } from '@/hooks';
import { updateRetailersAssignment } from '@/services/store.service';
import { useSession } from '@/store';
import { TableContainer } from '@/styles';
import { Store } from '@/types';
import { formatISODate } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import {
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Input,
  MenuProps,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import { Key, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { GoVerified } from 'react-icons/go';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { SiStackedit } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { TabContainer } from './styles';

export function StoresTab({ brandId }: { brandId: string }) {
  const audit = useAudit({
    resourceName: 'brand-stores-tab',
  });

  const session = useSession();

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('approved');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const { tableProps, refresh } = useTable<Store>({
    service: 'store/read',
    path: `admin/list`,
    params: {
      filters: {
        text: filter,
        brandId,
        status,
      },
      collection: 'stores',
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
  });

  const { mutate: onUpdateAssignment, isPending: updateAssignmentLoading } =
    useMutation({
      mutationFn: ({ status }: { status: 'approved' | 'blocked' }) => {
        return updateRetailersAssignment({
          status,
          storeIds: selectedRowKeys as string[],
          brandId,
        });
      },
      onSuccess: () => {
        notification.success({
          message: 'Success',
          description: `Successfully updated assignment status`,
        });
        setSelectedRowKeys([]);
        refresh();
      },
    });

  const appContainer = () => {
    return document.getElementById('app-container') as HTMLElement;
  };

  const getTableMenuItems = (store: Store): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `store-menu-manage-${store.id}`,
        label: <Link to={`/store/${store.id}/manage`}>Manage</Link>,
        icon: <SiStackedit size={20} />,
      },
    ];

    if (session.hasPermission('stores.edit')) {
      items.push({
        key: `store-edit-menu-${store.id}`,
        label: <Link to={`/store/${store.id}/edit`}>Edit</Link>,
        icon: <GoVerified size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'store-edit-button',
            data: {
              storeId: store.id,
            },
          });
        },
      });
    }

    return items;
  };

  return (
    <>
      <TabContainer>
        <TableContainer>
          <Table
            {...tableProps}
            rowKey={'id'}
            title={() => (
              <div className="table-header">
                <Space>
                  <Button
                    type={status === 'approved' ? 'primary' : 'default'}
                    onClick={() => setStatus('approved')}
                  >
                    Approved
                  </Button>
                  <Button
                    type={status === 'pending' ? 'primary' : 'default'}
                    onClick={() => setStatus('pending')}
                  >
                    Pending
                  </Button>
                  <Button
                    type={status === 'blocked' ? 'primary' : 'default'}
                    danger={status === 'blocked'}
                    onClick={() => setStatus('blocked')}
                  >
                    Rejected
                  </Button>
                </Space>

                <br />
                <div className="actions">
                  <Space>
                    <Input.Search
                      placeholder="Search by name"
                      allowClear
                      onSearch={(value) => {
                        setFilter(value);
                      }}
                      style={{ width: 260 }}
                    />
                  </Space>
                </div>
              </div>
            )}
            rowSelection={
              status !== 'pending' && session.hasPermission('stores.edit')
                ? undefined
                : {
                    selectedRowKeys,
                    onChange: (keys) => {
                      setSelectedRowKeys(keys);
                    },
                  }
            }
          >
            <Table.Column
              title="Name"
              dataIndex="name"
              key="name"
              sorter
              fixed="left"
              render={(text: any, record: Store, index: number) => {
                return (
                  <Space className="store-column">
                    <Avatar
                      src={record.logoUrl}
                      style={{ width: 48, height: 48 }}
                      icon={<BiUser />}
                      shape="circle"
                      size={48}
                    />

                    <Space direction="vertical" className="info">
                      <Typography.Title level={5}>
                        {record.name}
                      </Typography.Title>
                      <Typography.Text style={{ fontSize: 13 }}>
                        {record?.address?.formattedAddress}
                      </Typography.Text>
                    </Space>
                  </Space>
                );
              }}
            />

            <Table.Column
              title="Users"
              key="totalUsers"
              dataIndex="totalUsers"
              align="center"
              sorter
              render={(_, record: Store) => {
                return (
                  <Space.Compact direction={'vertical'}>
                    <Typography.Text ellipsis strong>
                      {record.totalUsers}
                    </Typography.Text>
                    {record.totalPendingUsers > 0 && (
                      <Typography.Text
                        ellipsis
                        type={'secondary'}
                        style={{ fontSize: 12 }}
                      >
                        Pending: {record.totalPendingUsers}
                      </Typography.Text>
                    )}
                  </Space.Compact>
                );
              }}
            />
            <Table.Column
              title={
                status === 'approved'
                  ? 'Connected At'
                  : status === 'pending'
                  ? 'Requested At'
                  : 'Rejected At'
              }
              dataIndex={status === 'rejected' ? 'rejectedAt' : 'createdAt'}
              sorter
              defaultSortOrder={'descend'}
              align="center"
              render={(text) => {
                return (
                  <div className="transaction-column-date">
                    <strong>{formatISODate(text, 'DD')}</strong>
                    <small>{formatISODate(text, 't')}</small>
                  </div>
                );
              }}
            />
            <Table.Column
              title="Actions"
              dataIndex="actions"
              key="actions"
              align="right"
              fixed="right"
              width={100}
              render={(text: any, record: Store, index: number) => {
                return (
                  <Dropdown menu={{ items: getTableMenuItems(record) }}>
                    <Button>
                      <PiDotsThreeOutline size={20} />
                    </Button>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </TableContainer>
      </TabContainer>
      <Drawer
        rootClassName={'table-footer-drawer'}
        mask={false}
        closable={false}
        open={
          session.hasPermission('stores.edit') && selectedRowKeys.length > 0
        }
        placement={'bottom'}
        getContainer={appContainer}
        height={74}
      >
        <div>
          <Typography.Text strong className="selected-count">
            {selectedRowKeys.length} selected
          </Typography.Text>
          <Button
            type={'link'}
            onClick={() => {
              setSelectedRowKeys([]);
            }}
          >
            Clear Selection
          </Button>
        </div>
        <div className="actions">
          <Button
            type="primary"
            loading={updateAssignmentLoading}
            onClick={() => onUpdateAssignment({ status: 'approved' })}
          >
            Approve
          </Button>
          <Button
            danger
            loading={updateAssignmentLoading}
            onClick={() => onUpdateAssignment({ status: 'blocked' })}
          >
            Reject
          </Button>
        </div>
      </Drawer>
    </>
  );
}
