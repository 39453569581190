export type VisibilityItem = 'allowed' | 'preview' | 'blocked';

export enum BrandPlanLevel {
  BASIC = 'basic',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
  PAY_AS_YOU_GO = 'pay-as-you-go',
}

export enum BrandPlanType {
  LEARNING = 'learning',
  CONTEST = 'contest',
  GENERAL = 'general',
  CONSUMER = 'consumer',
  NEWSFEED = 'newsfeed',
  PAY_AS_YOU_GO = 'pay-as-you-go',
}

export type BrandPlanPrice = {
  id?: string;
  currency: string;
  interval: 'month' | 'year';
  amount: number;
  extraPerSubmission: number;
  stripePriceId?: string;
  isActive?: boolean;
  createdAt?: string;
};

export type BrandPlan = {
  id?: string;
  name: {
    en: string;
    fr: string;
  };
  description: {
    en: string;
    fr: string;
  };
  level: BrandPlanLevel;
  isActive: boolean;
  type: BrandPlanType;
  limits: {
    liveCampaigns: {
      total: number | null;
      unlimited: boolean;
    };
    missionsPerCampaigns: {
      total: number | null;
      unlimited: boolean;
    };
    reachStores: {
      total: number | null;
      unlimited: boolean;
    };
    submissions: {
      total: number | null;
      unlimited: boolean;
    };
    totalSales: {
      total: number | null;
      unlimited: boolean;
    };
    newsfeed: {
      total: number | null;
      unlimited: boolean;
    };
  };
  appVisibility: {
    stores: VisibilityItem;
    campaigns: VisibilityItem;
    missions: VisibilityItem;
    taxForm: VisibilityItem;
    groups: VisibilityItem;
    dataInsights: VisibilityItem;
    submissions: VisibilityItem;
    newsfeed: VisibilityItem;
    forms: VisibilityItem;
    inbox: VisibilityItem;
  };
  prices: Array<BrandPlanPrice>;
  kpi?: {
    totalBrands: number;
  };
  stripe?: {
    priceId: string;
    productId: string;
  };
  createdAt: string;
  updatedAt: string;
};
