import { useSession } from '@/store/session';
import {
  AuditOutlined,
  MobileOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { CheckSquare, Receipt, Rocket, Settings } from 'lucide-react';
import { AiOutlineImport, AiOutlineSolution } from 'react-icons/ai';
import { FaFileExcel, FaRegBuilding } from 'react-icons/fa';
import { FaEarthAmericas } from 'react-icons/fa6';
import { GrMapLocation } from 'react-icons/gr';
import { HiOutlineCog8Tooth } from 'react-icons/hi2';
import { MdOutlineSatelliteAlt, MdStorefront } from 'react-icons/md';
import { RiShieldFlashLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

type ChildrenAndVisibility = {
  visible?: boolean;
  children?: ChildrenAndVisibility[];
};

const removeVisibleField = <T extends ChildrenAndVisibility>(item: T) => {
  delete item.visible;
  if (item.children) {
    item.children.forEach(removeVisibleField);
  }

  return item;
};

const Sidebar = () => {
  const session = useSession();

  const items: React.ComponentProps<typeof Menu>['items'] = [
    {
      key: 'map-dashboard-menu-key',
      label: <Link to="/dashboard/map">Map Dashboard</Link>,
      icon: <GrMapLocation />,
      visible: session.hasPermission('report.view'),
    },
    {
      key: 'mobile-user-menu-key0',
      label: <span>Mobile Users</span>,
      icon: <MobileOutlined />,
      visible: session.hasPermission('users.view'),
      children: [
        {
          key: 'mobile-user-menu-key1',
          label: <Link to="/mobile-user">Users List</Link>,
          visible: session.hasPermission('users.view'),
        },
        // {
        //   key: 'mobile-user-menu-key2',
        //   label: <Link to="/mobile-user/pending-store-requests">Pending Store Requests</Link>,
        //   visible: session.hasPermission('users.edit'),
        // },
        {
          key: 'mobile-user-menu-key3',
          label: <Link to="/users-poe-list">Users Pending POEs</Link>,
          visible: session.hasPermission('users.edit'),
        },
      ],
    },
    {
      key: 'brand-menu-key-0',
      label: <span>Brand</span>,
      icon: <ShoppingOutlined />,
      visible: session.hasPermission('brands.view'),
      children: [
        {
          key: 'brand-menu-key1',
          label: <Link to="/brand">Brand List</Link>,
          visible: session.hasPermission('brands.view'),
        },
        {
          key: 'brand-plans-menu-key2',
          label: <Link to="/brand/plans">Brand Plans</Link>,
          visible: session.hasPermission('brand_plans.view'),
        },
        {
          key: 'brand-menu-key2',
          label: <Link to="/brand/web-users">Web Users</Link>,
          visible: session.hasPermission('web_user.view'),
        },
      ],
    },
    {
      key: 'retailer-menu-key-0',
      label: <Link to="/retailer">Retailers</Link>,
      icon: <AiOutlineSolution />,
      visible: session.hasPermission('brands.view'),
    },
    {
      key: 'store-menu-key-0',
      label: <Link to="/store">Store</Link>,
      icon: <MdStorefront />,
      visible: session.hasPermission('stores.view'),
    },
    {
      key: 'campaign-menu-key-0',
      label: <Link to="/campaign">Campaign</Link>,
      icon: <Rocket />,
      visible: session.hasPermission('campaigns.view'),
    },
    {
      key: 'mission-approval-menu-key-0',
      label: <Link to="/mission-approval">Mission Approval</Link>,
      icon: <CheckSquare />,
      visible:
        session.hasPermission('consumer_completion.view') ||
        session.hasPermission('sales_incentive_completion.view'),
    },
    {
      key: 'cashout-request-menu-key-0',
      label: <Link to="/cashout-request">Cashout Request</Link>,
      icon: <Receipt />,
      visible: session.hasPermission('cashout_request.view'),
    },
    {
      key: 'company-menu-key-0',
      label: <Link to="/company">Company</Link>,
      icon: <FaRegBuilding />,
      visible: session.hasPermission('companies.view'),
    },
    {
      key: 'country-menu-key-0',
      label: <Link to="/country">Countries</Link>,
      icon: <FaEarthAmericas />,
      visible: session.hasPermission('countries.view'),
    },
    {
      key: 'report-menu-key-0',
      label: <Link to="/reports">Reports</Link>,
      icon: <FaFileExcel />,
      visible: session.hasPermission('report.view'),
    },
    {
      key: 'integration-menu-key',
      label: <span>Integration</span>,
      icon: <MdOutlineSatelliteAlt />,
      visible: session.hasPermission('integration.view'),
      children: [
        {
          key: 'integration-menu-key-1',
          label: <Link to="/integration/connector/list">Connectors List</Link>,
          visible: true,
        },
      ],
    },
    {
      key: 'dev-team-menu-key',
      label: <span>Dev Management</span>,
      icon: <RiShieldFlashLine />,
      visible: true,
      children: [
        {
          key: 'dev-team-menu-key-0',
          label: <Link to="/messaging-controller">Messaging Controller</Link>,
          visible: session.hasPermission('messaging_controler.view'),
        },
        {
          key: 'dev-team-menu-key-1',
          label: <Link to="/dev/queue/lists">Queue Lists</Link>,
          visible: session.hasPermission('queue.view'),
        },
        {
          key: 'dev-team-menu-key-2',
          label: <Link to="/dev/queue/jobs">Queue Jobs</Link>,
          visible: session.hasPermission('queue.view'),
        },
      ],
    },
    {
      key: 'dealer-menu-key',
      label: <Link to="/dealer-import">Dealer Import</Link>,
      icon: <AiOutlineImport />,
      visible: session.hasPermission('stores.edit'),
    },
    {
      key: 'settings-menu-key',
      label: <span>Administration</span>,
      icon: <HiOutlineCog8Tooth />,
      visible: true,
      children: [
        {
          key: 'administration-key-0',
          label: <Link to="/admin-user">Admin Users</Link>,
          icon: <AuditOutlined />,
          visible: session.hasPermission('admin_user.view'),
        },
        {
          key: 'administration-key-1',
          label: <Link to="/settings">General Settings</Link>,
          icon: <Settings />,
          visible: session.hasPermission('billing.view'),
        },
      ],
    },
  ]
    .map((item) => ({
      ...item,
      children: item.children
        ? item.children.filter((child) => child.visible)
        : undefined,
    }))
    .filter((item) => {
      return !(
        !item.visible ||
        (item.children !== undefined && !item.children.length)
      );
    })
    .map(removeVisibleField);

  return (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      items={items}
    />
  );
};

export default Sidebar;
