import { useAudit } from '@/hooks';
import { getSettings, saveSettings } from '@/services/settings.service';
import { getMobilePayments } from '@/services/user-wallet.service';
import { MobilePayments } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { Form, notification } from 'antd';
import { values } from 'lodash';
import { useEffect, useState } from 'react';

interface Settings {
  [key: string]: {
    type: string;
    values: any;
    updatedAt?: string;
  };
}

export function useSettingsController() {
  const [mobilePayments, setMobilePayments] = useState<MobilePayments>();
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [savingPayments, setSavingPayments] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentTab, setCurrentTab] = useState('global');
  const [savingSettings, setSavingSettings] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [form] = Form.useForm();

  const audit = useAudit({
    resourceName: 'general-settings',
  });

  useEffect(() => {
    audit.onAccess();
    loadMobilePayments();
    loadSettings();
  }, [currentTab]);

  const {
    data: settings,
    refetch: loadSettings,
    isLoading: loadingSettings,
  } = useQuery({
    queryKey: ['settings', currentTab],
    queryFn: async () => {
      const result = await getSettings(currentTab);
      if (!result) {
        return {};
      }

      form.setFieldsValue(result);

      return result.values;
    },
  });

  const loadMobilePayments = async () => {
    // TODO: Remove this once we have the mobile payments working
    return;
    setLoadingPayments(true);
    const { result } = await getMobilePayments();
    setMobilePayments(result);
    setTimeout(() => {
      setLoadingPayments(false);
    }, 0);
    form.setFieldsValue({ payments: result });
  };

  const onFinish = async (values: any) => {
    try {
      setSavingSettings(true);
      await saveSettings({
        type: currentTab,
        values: values.values,
      });
      audit.onUpdate({ target: 'save', data: values });
      notification.success({
        message: 'Success',
        description: 'Settings saved successfully.',
      });
      setHasChanges(false);
      await loadSettings();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to save settings. Please try again later.',
      });
    } finally {
      setSavingSettings(false);
    }
  };

  const onFieldsChange = () => {
    setHasChanges(true);
  };

  const onCancel = () => {
    form.setFieldsValue({ values: settings });
    setHasChanges(false);
  };

  return {
    audit,
    mobilePayments,
    loadingPayments,
    form,
    disabled,
    onFinish,
    onFieldsChange,
    hasChanges,
    savingPayments,
    onCancel,
    savingSettings,
    currentTab,
    setCurrentTab,
    settings,
    loadingSettings,
  };
}
