import { Form, Input, Button } from 'antd';
import { PaymentFormContainer } from '../styles';

interface DepositBankInfoSettingsProps {
  form: any;
  onFinish: (values: any) => void;
  disabled: boolean;
  onFieldsChange: () => void;
  hasChanges: boolean;
  onCancel: () => void;
  saving: boolean;
}

export function DepositBankInfoSettings({
  form,
  onFinish,
  disabled,
  onFieldsChange,
  hasChanges,
  onCancel,
  saving,
}: DepositBankInfoSettingsProps) {
  return (
    <PaymentFormContainer>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        {['CA', 'US', 'UK', 'AU', 'NZ'].map((country) => (
          <div key={country}>
            <h3>{country} Bank Information</h3>
            <Form.Item
              name={['values', country, 'hint']}
              label="Hint"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['values', country, 'label']}
              label="Label"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            {/* Dynamic fields based on country */}
            {['Bank Name', 'Account Name', 'Account Number'].map((field) => (
              <Form.Item
                key={field}
                name={[
                  'values',
                  country,
                  'fields',
                  field.toLowerCase().replace(' ', '_'),
                ]}
                label={field}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ))}
          </div>
        ))}

        <Form.Item className="actions">
          {hasChanges && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" disabled={saving}>
                Save
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </PaymentFormContainer>
  );
}
