import { Form, Input, Button, Divider } from 'antd';
import { PaymentFormContainer } from '../styles';

interface WebappMissionsSettingsProps {
  form: any;
  onFinish: (values: any) => void;
  disabled: boolean;
  onFieldsChange: () => void;
  hasChanges: boolean;
  onCancel: () => void;
  saving: boolean;
}

export function WebappMissionsSettings({
  form,
  onFinish,
  disabled,
  onFieldsChange,
  hasChanges,
  onCancel,
  saving,
}: WebappMissionsSettingsProps) {
  return (
    <PaymentFormContainer>
      <Form
        form={form}
        onFinish={onFinish}
        disabled={disabled}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Divider orientation="left" orientationMargin="0">
          General
        </Divider>
        <Form.Item
          name={['values', 'general', 'employee_survey_brand']}
          label="Employee Survey Brand"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['values', 'general', 'inventory']}
          label="Inventory"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['values', 'general', 'merchandise']}
          label="Merchandise"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['values', 'general', 'rep_account']}
          label="Rep Account"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['values', 'general', 'social_media']}
          label="Social Media"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Contest
        </Divider>
        <Form.Item
          name={['values', 'contest', 'employee_contest']}
          label="Employee Contest"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['values', 'contest', 'store_contest']}
          label="Store Contest"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          Learning
        </Divider>
        <Form.Item
          name={['values', 'learning', 'product']}
          label="Product"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="actions">
          {hasChanges && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              &nbsp;
              <Button type="primary" htmlType="submit" disabled={saving}>
                Save
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </PaymentFormContainer>
  );
}
